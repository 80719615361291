import { Stack, TextField } from '@mui/material';
import React from 'react';

const JobCreation = (props) => {
	const { details, setDetails } = props;
	const onChange = (e) => {
		const { name, value } = e.target;
		setDetails({
			...details,
			[name]: value,
		});
	};
	return (
		<Stack gap={2}>
			<TextField
				label={'Job Role'}
				name="job_role"
				value={details.job_role}
				onChange={onChange}
				style={{
					width: '610px',
				}}
			/>
			<TextField
				label={'Job Description'}
				name="job_description"
				value={details.job_description}
				onChange={onChange}
				fullWidth
				multiline
				rows={4}
			/>
			<TextField
				label={'Job Poster Link'}
				name="job_poster"
				value={details.job_poster}
				onChange={onChange}
				style={{
					width: '610px',
				}}
			/>
		</Stack>
	);
};

export default JobCreation;
