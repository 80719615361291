import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LaunchScreen } from './components/LaunchScreen';
import { Login } from './components/Login';
import { AdminDashboard } from './components/AdminDashboard';
import { CandidateReport } from './components/CandidateReport';
import CandidateReportNew from './components/CandidateReport/CandidateReportNew';
import CandidateReportOld from './components/CandidateReport/CandidateReportOld';
import { CreateInterview } from './components/CreateInterview';
import CandidateReportV6 from './components/CandidateReport/candidateReportV6';
import { AdminContextProvider } from './global/AdminContext';
import { InterviewContext } from './global/InterviewContext';
// import PracticeInterview from "./components/PracticeInterview";
import CollegeAdminpage from './components/CollegeAdminDashboard/index';
import CollegeInterviewDetails from './components/CollegeAdminDashboard/collegeinterviewdetails';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import AddApplicant from './components/AddApplicant';
import IncompleteInterviews from './components/AdminDashboard/IncompleteInterviews';
import TotalApplicants from './components/AdminDashboard/TotalApplicants';
import AllReports from './components/AllReports/AllReports';
import EnvironmentCheck from './components/EnvironmentCheck/EnvironmentCheck';
import InternetSpeed from './components/EnvironmentCheck/InternetSpeed';
import { InitialQuestions } from './components/InitialQuestions';
import { InterviewTrial } from './components/Interview/InterviewTrial';
import { InterviewTrivia } from './components/InterviewTrivia';
import LandingPage from './components/LandingPage/LandingPage';
import { VideoUpload } from './components/MultipartVideoUpload/VideoUpload';
import { PracticeInterviewNew } from './components/PracticeInterview/PracticeInterviewNew';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import { VideoAndScreenRecorder } from './components/VideoAndScreenRecorder';
import WebcamStream from './components/WebcamStream';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import ResumeEvaluation from './components/AdminDashboard/ResumeEvaluation';
import ViewResumeEvaluation from './components/AdminDashboard/ViewResumeEvaluation';
import CandidateReportNewFlow from './components/CandidateReport/CandidateReportNewFlow';
import CodeEditor from './components/CodeEditor';
import { EndCodingScreen } from './components/CodeEditor/EndCodingScreen';
import CompaniesAndJobs from './components/CompaniesAndJob';
import CreateJobPosting from './components/CompaniesAndJob/CreateJobPosting';
import CreateCompany from './components/CreateCompany';
import CreateInterviewQuestions from './components/CreateInterviewQuestions';
import { Demo } from './components/Demo';
import { DemoInterview } from './components/Demo/DemoInterview';
import DemoReport from './components/Demo/DemoReport';
import { EndScreen } from './components/EndScreen';
import { ScreenShareViolation } from './components/ScreenShareViolation';
import SmashDemo from './components/SmashDemo/SmashDemo';
import CandidateReportV4 from './components/CandidateReport/CandidateReportV4';
import CandidateReportV5 from './components/CandidateReport/candidateReportV5';

import CodingTestReport from './components/CandidateReport/CodingTestReport';
import EditJob from './components/CreateCompany/EditJob';

import AllInterviews from './components/Student/AllInterviews';
import CompleteProfile from './components/Student/CompleteProfile';
import StudentDashboard from './components/Student/StudentDashboard';
import StudentLogin from './components/Student/StudentLogin';
import StudentReport from './components/Student/StudentReport';

import CollegeStudentReport from './components/CollegeAdminDashboard/StudentReports';
import EditCompany from './components/EditCompany';
import CreateJobdescription from './components/Jobdescription/create';
import WriteModal from './components/jobdescriptioncheck/jobdescriptioncheck';
import ViolationEndScreen from './components/EndScreen/ViolationEndScreen';
import Contact from './components/LandingPage/components/Contact';
import HeroSection from './components/LandingPage/components/HeroSection';
import ContactPage from './components/LandingPage/components/ContactPage';
import CompleteStudentProfile from './components/Student/mock-interviews/CompleteStudentProfile';
import MockInterviewDashboard from './components/Student/mock-interviews/Dashboard';
import MockInterviewReport from './components/Student/mock-interviews/MockInterviewReport';
import AdminMockInterviewDashboard from './components/AdminMockInterview/AdminMockInterviewDashboard';
import AllStudentMockInterviewReport from './components/AdminMockInterview/AllStudentMockInterviewReport';
import CreateMockInterviewQuestions from './components/AdminMockInterview/CreateMockInterviewQuestions';
import AdminMockInterviewReport from './components/AdminMockInterview/AdminMockInterviewReport';
const theme = createTheme({
	typography: {
		allVariants: {
			fontFamily: 'Gordita',
		},
	},
});

function App() {
	const [interviewEnded, setInterviewEnded] = useState(false);

	return (
		<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
			<ThemeProvider theme={theme}>
				<div className="App">
					<SnackbarProvider
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<AdminContextProvider>
							<InterviewContext.Provider
								value={{ interviewEnded, setInterviewEnded }}
							>
								<BrowserRouter>
									<Routes>
										<Route path="/" element={<LandingPage />} />
										<Route
											path="/contact"
											element={
												<>
													<ContactPage />
												</>
											}
										/>
										<Route path="/home" element={<LaunchScreen />} />
										<Route path="/privacy-policy" element={<PrivacyPolicy />} />
										<Route path="/terms-of-use" element={<TermsOfUse />} />
										<Route path="/login/:type" element={<Login />} />
										<Route
											path="/check/:fullName/:interview_key"
											element={<EnvironmentCheck />}
										/>
										<Route path="/internet-speed" element={<InternetSpeed />} />
										<Route path="/interview" element={<InterviewTrial />} />

										<Route
											path="/interview/practice/"
											element={<PracticeInterviewNew />}
										/>
										<Route
											path="/candidate/report-v1/:key"
											element={<CandidateReportOld />}
										/>
										<Route
											path="/candidate/report-v2/:key"
											element={<CandidateReportNew />}
										/>
										<Route
											path="/candidate/report-v3/:key"
											element={<CandidateReportNewFlow />}
										/>
										<Route
											path="/candidate/report-v4/:key"
											element={<CandidateReportV4 />}
										/>
										<Route
											path="/candidate/report-v5/:key"
											element={<CandidateReportV5 />}
										/>
										<Route
											path="/candidate/report-v6/:key"
											element={<CandidateReportV6 />}
										/>
										<Route
											path="/candidate/report/:key"
											element={<CandidateReport />}
										/>
										<Route
											path="/dashboard/jobslist/company"
											element={<AdminDashboard />}
										/>
										<Route
											path="/edit-company/:companyId"
											element={<EditCompany />}
										/>

										<Route
											path="/dashboard/:type"
											element={<CompaniesAndJobs />}
										/>
										<Route
											path="/create-interview"
											element={<CreateInterview />}
										/>
										<Route path="/create/:type" element={<CreateCompany />} />
										<Route
											path="/create-interview-questions/:job_id"
											element={<CreateInterviewQuestions />}
										/>
										<Route
											path="/create-mock-interviews"
											element={<CreateMockInterviewQuestions />}
										/>
										<Route
											path="/edit-mock-interview/:job_id/:pilot_id"
											element={<CreateMockInterviewQuestions type="edit" />}
										/>
										<Route
											path="/mock-interview/report/:key"
											element={<AdminMockInterviewReport />}
										/>
										<Route
											path="/edit-interview-questions/:job_id/:pilot_id"
											element={<CreateInterviewQuestions type="edit" />}
										/>
										<Route
											path="/create-job-posting/:role/:company_id"
											element={<CreateJobPosting />}
										/>
										<Route
											path="/initial-questions"
											element={<InitialQuestions />}
										/>
										<Route
											path="/add-candidate/:role/:company_id/:job_id"
											element={<AddApplicant />}
										/>
										<Route path="/edit-job/:job_id" element={<EditJob />} />

										{/* For Testing */}
										<Route
											path="/job/details/:job_id"
											element={<AllReports />}
										/>
										<Route
											path="/job/incomplete-interviews/:job_id/:type"
											element={<IncompleteInterviews />}
										/>
										<Route
											path="/:type/total-applicants/:job_id"
											element={<TotalApplicants />}
										/>
										<Route
											path="/job/resume-evaluation/:company_id/:job_id"
											element={<ResumeEvaluation />}
										/>
										<Route
											path="/job/view-resume-evaluation/:company_id/:job_id"
											element={<ViewResumeEvaluation />}
										/>
										<Route
											path="/interview"
											element={<VideoAndScreenRecorder />}
										/>
										<Route
											path="/multipartvideo"
											element={
												<VideoUpload
													interviewKey="123"
													hasInterviewEnded={false}
												/>
											}
										/>
										<Route path="/trivia" element={<InterviewTrivia />} />

										{/* Student Routes */}
										<Route path="/student/login" element={<StudentLogin />} />
										<Route
											path="/student/complete-profile"
											element={<CompleteProfile />}
										/>
										<Route
											path="/student/mock-interview/complete-profile"
											element={<CompleteStudentProfile />}
										/>
										<Route
											path="/student/mock-interview/dashboard"
											element={<MockInterviewDashboard />}
										/>
										<Route
											path="/mock-interviews/dashboard"
											element={<AdminMockInterviewDashboard />}
										/>
										<Route
											path="/mock-interviews/all-reports"
											element={<AllStudentMockInterviewReport />}
										/>
										<Route
											path="/student/mock-interview/all-report"
											element={<MockInterviewReport />}
										/>
										<Route
											path="/student/dashboard"
											element={<StudentDashboard />}
										/>
										<Route
											path="/student/all-interviews"
											element={<AllInterviews />}
										/>
										{/* <Route
                      path="/student/interview/:fullName/:interview_key"
                      element={<InterviewScreen />}
                    /> */}
										<Route
											path="/student/report/:key"
											element={<StudentReport />}
										/>
										<Route path="/webcam-stream" element={<WebcamStream />} />
										<Route path="/demo" element={<Demo type="candidate" />} />
										<Route
											path="/demo/:fullName/:interview_key"
											element={<DemoInterview />}
										/>
										<Route path="/demo/report/:key" element={<DemoReport />} />
										<Route
											path="/coding-test/:fullName/:interview_key"
											element={<CodeEditor />}
										/>
										<Route
											path="/coding-test/report"
											element={<CodingTestReport />}
										/>
										{/* College interviews */}
										<Route
											path="/college-admin"
											element={<CollegeAdminpage />}
										/>
										<Route
											path="/college-interview/total-applicants/:id"
											element={<CollegeAdminpage />}
										/>
										<Route
											path="/college-admin/:id"
											element={<CollegeInterviewDetails />}
										/>
										<Route
											path="/collegestudentReport/:key"
											element={<CollegeStudentReport />}
										/>

										{/* Smash Demo */}

										<Route path="/smash-demo" element={<SmashDemo />} />
										<Route
											path="/violation/:interview_key"
											element={<ScreenShareViolation />}
										/>
										<Route
											path="/end/:fullName/:interview_key"
											element={<EndScreen />}
										/>
										<Route
											path="/end/violation/:fullName/:interview_key"
											element={<ViolationEndScreen />}
										/>
										<Route
											path="/jobdescription/create"
											element={<CreateJobdescription />}
										/>
										<Route
											path="/jobdescription/edit/:id"
											element={<CreateJobdescription />}
										/>
										<Route
											path="/jobdescription/check"
											element={<WriteModal />}
										/>

										<Route path="*" element={<p>page not found</p>} />
									</Routes>
								</BrowserRouter>
							</InterviewContext.Provider>
						</AdminContextProvider>
					</SnackbarProvider>
				</div>
			</ThemeProvider>
		</GoogleOAuthProvider>
	);
}

export default App;
