import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import QuizIcon from '@mui/icons-material/Quiz';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Box, Button, IconButton, Paper, Tooltip, Typography, styled } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { API_URL } from '../../global/constants';
import { Header } from '../shared/Header';
import DeleteJobDialog from './DeleteJobDialog';
import QuestionGenerationPopup from './QuestionGenerationPopup';
import ReminderEmailDialog from './ReminderEmailDialog';
import DownloadIcon from '@mui/icons-material/Download';
import CustomToolbar from './CustomToolbar';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-columnHeaders': {
		background: '#224C84',
	},
	'& .MuiDataGrid-menuIcon button': {
		color: '#fff',
	},
	'& .MuiDataGrid-iconButtonContainer button': {
		color: '#fff',
	},
	'&  .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
		color: '#fff',
		fontWeight: 'bold',
	},
}));
const filterRows = (rows, filterValue) => {
	return rows.filter((row) => {
		return Object.values(row).some((value) =>
			value.toString().toLowerCase().includes(filterValue.toLowerCase())
		);
	});
};

const AllJobPostingTable = ({ jobPostings, setJobPostings }) => {
	const reversedJobPostingsRows = [...jobPostings].reverse();
	const navigate = useNavigate();
	const [filteredRows, setFilteredRows] = useState(jobPostings);

	useEffect(() => {
		const filterValue = sessionStorage.getItem('filterValue');
		if (filterValue) {
			setFilteredRows(filterRows(jobPostings, filterValue));
		} else {
			setFilteredRows(reversedJobPostingsRows);
		}
	}, []);

	const handleFilterChange = (filterValue) => {
		if (filterValue) {
			setFilteredRows(filterRows(jobPostings, filterValue));
		} else {
			setFilteredRows(reversedJobPostingsRows);
		}
	};

	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [reminderMailDialogOpen, setReminderMailDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [templateId, setTemplateId] = useState('');
	const [jobId, setJobId] = useState('');
	const [jobRole, setJobRole] = useState('');
	const [sendReminderMailLoading, setSendReminderMailLoading] = useState(false);
	const { type } = useParams();
	const [showPopup, setShowPopup] = useState(false);
	const checkandnavigate = async (_id) => {
		const data = await axios.get(`${API_URL}/labs/job/${_id}`);
		if (data.data.success) {
			navigate(`/jobdescription/edit/${_id}`);
		} else {
			navigate(`/edit-job/${_id}`);
		}
	};
	const [loading, setLoading] = useState(false);
	const [flag, setFlag] = useState(0);

	const columns = [
		{
			field: 'jobRole',
			headerName: 'Job Role',
			width: 300,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						textAlign: 'start',
						color: '#224C84',
						whiteSpace: 'wrap',
					}}
					onClick={() => {
						navigate(`/job/details/${params.row.id}`);
					}}
				>
					{params.row.jobRole}
				</Button>
			),
		},
		{
			field: 'totalApplicants',
			headerName: 'Total Applicants',
			align: 'center',
			headerAlign: 'center',
			width: 200,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						color: '#224C84',
					}}
					onClick={() => navigate(`/job/total-applicants/${params.row.id}`)}
				>
					{params.row.totalApplicants}
				</Button>
			),
		},
		{
			field: 'allCompletedInterviews',
			headerName: 'Total Completed Interviews',
			align: 'center',
			headerAlign: 'center',
			width: 220,
		},
		{
			field: 'allIncompletedInterviews',
			headerName: 'Total Incomplete Interviews',
			align: 'center',
			headerAlign: 'center',
			width: 220,
			renderCell: (params) => (
				<Button
					sx={{
						fontSize: '14px',
						textTransform: 'none',
						color: '#224C84',
					}}
					onClick={() => navigate(`/job/incomplete-interviews/${params.row.id}/company`)}
				>
					{params.row.allIncompletedInterviews}
				</Button>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			filterable: false,
			sortable: false,
			renderCell: (params) => (
				<>
					<Tooltip title="Add Applicant">
						<IconButton
							onClick={() =>
								navigate(
									`/add-candidate/company/${params.row.companyId}/${params.row.id}`
								)
							}
							sx={{
								color: '#224C84',
							}}
						>
							<PersonAddIcon />
						</IconButton>
					</Tooltip>
					{isSuperAdmin && (
						<>
							<Tooltip title="Send Reminder Email">
								<IconButton
									onClick={() => {
										setJobId(params.row.id);
										setReminderMailDialogOpen(true);
									}}
									sx={{
										color: '#224C84',
									}}
								>
									<ScheduleSendIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Upload resumes for evaluation">
								<IconButton
									onClick={() => {
										navigate(
											`/job/resume-evaluation/${params.row.companyId}/${params.row.id}`,
											{
												state: {
													jobRole: params.row.jobRole,
													jobId: params.row.id,
													companyId: params.row.companyId,
												},
											}
										);
									}}
									sx={{
										color: '#224C84',
									}}
								>
									<PostAddIcon />
								</IconButton>
							</Tooltip>
						</>
					)}
					<Tooltip title="View resume evaluation">
						<IconButton
							onClick={() => {
								navigate(
									`/job/view-resume-evaluation/${params.row.companyId}/${params.row.id}`,
									{
										state: {
											jobRole: params.row.jobRole,
											jobId: params.row.id,
											companyId: params.row.companyId,
										},
									}
								);
							}}
							sx={{
								color: '#224C84',
							}}
						>
							<WysiwygIcon />
						</IconButton>
					</Tooltip>
					{isSuperAdmin && (
						<>
							<Tooltip title="Edit Job Posting">
								<IconButton
									onClick={() => {
										checkandnavigate(params.row.id);
									}}
									sx={{
										color: '#224C84',
									}}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
							{params.row.questions_link === '' ? (
								<Tooltip title="Generate Questions">
									<IconButton
										onClick={() => {
											console.log('clicked');
											generateQuestions(params.row.id, params.row.jobRole); // Ensure this function is defined
										}}
										sx={{
											color: '#224C84',
										}}
									>
										<QuizIcon />
									</IconButton>
								</Tooltip>
							) : (
								<Tooltip title="Download Questions">
									<IconButton
										onClick={() => {
											console.log('clicked next');
											//generateQuestions(params.row.id, params.row.jobRole); // Ensure this function is defined
											downloadGeneratedQuestions(params.row.questions_link);
										}}
										sx={{
											color: '#224C84',
										}}
									>
										<DownloadIcon />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip title="Delete Job Posting">
								<IconButton
									onClick={() => {
										setDeleteDialogOpen(true);
										setJobId(params.row.id);
										setJobRole(params.row.jobRole);
									}}
									sx={{
										color: 'red',
									}}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</>
					)}
				</>
			),
		},
	];

	const generateQuestions = async (jobId, jobRole) => {
		try {
			const { data } = await axios.get(
				`${API_URL}/labs/get-questions-generation-flag/${jobId}`
			);

			console.log(data.data.questions_generation_flag);
			console.log(flag);

			if (data.data.questions_generation_flag === 0) {
				setFlag(1);
				setJobRole(jobRole);
				setShowPopup(true);

				const { flag_data } = await axios.post(
					`${API_URL}/labs/set-questions-generation-flag/${jobId}`,
					{ questions_generation_flag: 1 }
				);

				console.log('Generate Questions function called');
				const { data } = await axios.get(
					`${API_URL}/labs/create-interview-questions/${jobId}`
				);
				console.log(data);

				if (data && data.data) {
					downloadQuestionsAsExcel(data.data, jobRole, jobId);
				}
				//downloadQuestionsAsExcel(jobRole, jobId)
			}
			if (data.data.questions_generation_flag === 1) {
				setFlag(1);
				setJobRole(jobRole);
				setShowPopup(true);
			}
			//downloadQuestionsAsExcel();
			if (data.data.questions_generation_flag === 2) {
				const downloadLink = data.data.questions_link;
				const link = document.createElement('a');
				link.href = downloadLink;
				link.setAttribute('download', jobRole.xlsx); // Specify the name of the file to download
				// Append the anchor element to the document body
				document.body.appendChild(link);

				// Programmatically click the anchor element to start the download
				link.click();

				// Remove the anchor element from the document body
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const downloadGeneratedQuestions = async (downloadLink) => {
		const link = document.createElement('a');
		link.href = downloadLink;
		link.setAttribute('download', jobRole.xlsx); // Specify the name of the file to download
		// Append the anchor element to the document body
		document.body.appendChild(link);

		// Programmatically click the anchor element to start the download
		link.click();

		// Remove the anchor element from the document body
		document.body.removeChild(link);
	};

	const downloadQuestionsAsExcel = async (InterviewQuestions, jobRole, jobId) => {
		const { interviewQuestions, definitionsResponse } = InterviewQuestions;

		const combinedQuestions = interviewQuestions
			.filter((question) => /\d/.test(question.question_id)) // Filter out questions where question_id does not contain a number
			.map((question, index) => {
				const definition = definitionsResponse.find(
					(def) => def.question_id === question.question_id
				);
				return {
					'S.No.': question.question_id,
					Skill: question.skill_accessed,
					Subskill: question.sub_skill_accessed,
					Questions: question.question,
					'Question Type': question.question_type,
					'Definition of candidate answer': definition
						? definition.answer_definition
						: '',
				};
			});

		// Create a new workbook and add a new worksheet
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Interview Questions');

		// Define the header row
		const header = [
			'S.No.',
			'Skill',
			'Subskill',
			'Questions',
			'Question Type',
			'Definition of candidate answer',
		];
		worksheet.addRow(header);

		// Apply styles to the header row
		worksheet.getRow(1).eachCell((cell) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'FFFF00' },
			};
			cell.font = {
				bold: true,
			};
			cell.alignment = {
				wrapText: true,
				vertical: 'middle',
				horizontal: 'center',
			};
		});
		worksheet.getRow(1).height = 50; // Increase the height of the first row

		// Set specific widths and wrap text for the specified columns
		worksheet.getColumn(1).width = 10;
		worksheet.getColumn(2).width = 30;
		worksheet.getColumn(3).width = 30;
		worksheet.getColumn(4).width = 120;
		worksheet.getColumn(5).width = 30;
		worksheet.getColumn(6).width = 200;

		// Enable text wrapping for the specified columns

		// Add the combined questions to the worksheet
		combinedQuestions.forEach((data) => {
			worksheet.addRow(Object.values(data));
		});

		[1, 2, 3, 4, 5, 6].forEach((colNumber) => {
			worksheet.getColumn(colNumber).eachCell({ includeEmpty: true }, (cell, rowNumber) => {
				cell.alignment = {
					wrapText: true,
					vertical: 'middle',
					horizontal: rowNumber === 1 ? 'center' : 'left',
				};
			});
		});

		console.log(worksheet.rowCount);

		// Set the height for all rows (starting from the second row)
		for (let i = 2; i <= worksheet.rowCount; i++) {
			console.log(i);
			worksheet.getRow(i).height = 50;
		}

		// Generate the file and trigger a download
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		console.log('completed');
		uploadToBackend(blob, jobId, jobRole);
		saveAs(blob, `${jobRole}.xlsx`);
		console.log(jobId);
		alert(`${jobRole}, Interview questions are ready.`);
		const { flag_data } = await axios.post(
			`${API_URL}/labs/set-questions-generation-flag/${jobId}`,
			{ questions_generation_flag: 2 }
		);
	};

	const uploadToBackend = async (blob, jobId, jobRole) => {
		const formData = new FormData();
		formData.append('file', blob, `${jobRole}.xlsx`);
		formData.append('jobRole', jobRole);

		try {
			const response = await axios.post(
				`${API_URL}/labs/upload-questions/${jobId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			console.log('Upload successful', response.data);
		} catch (error) {
			console.error('Upload failed', error);
		}
	};

	const handleReminderMailDialogClose = () => {
		setReminderMailDialogOpen(false);
		setJobId('');
		setTemplateId('');
	};

	const sendReminderMail = async () => {
		try {
			console.log(jobId);
			setSendReminderMailLoading(true);
			const { data } = await axios.get(
				`${API_URL}/labs/mail/reminder/${jobId}/${templateId}`
			);
			if (data.success) {
				toast.success('Reminder Mail Sent Successfully');
			} else {
				toast.error('Something went wrong, Try Again!!');
			}
			setSendReminderMailLoading(false);
			handleReminderMailDialogClose();
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
	};

	useEffect(() => {
		const roles = JSON.parse(sessionStorage.getItem('admin')).roles;
		if (roles.includes('super-admin')) {
			setIsSuperAdmin(true);
		}
	}, []);

	return (
		<>
			<div style={{ backgroundColor: '#ECF7FA' }}>
				<div>
					{showPopup && (
						<QuestionGenerationPopup
							jobRole={jobRole}
							onClose={() => setShowPopup(false)}
						/>
					)}
				</div>
				<ReminderEmailDialog
					handleReminderMailDialogClose={handleReminderMailDialogClose}
					jobId={jobId}
					reminderMailDialogOpen={reminderMailDialogOpen}
					sendReminderMail={sendReminderMail}
					sendReminderMailLoading={sendReminderMailLoading}
					setTemplateId={setTemplateId}
					templateId={templateId}
				/>
				<DeleteJobDialog
					open={deleteDialogOpen}
					setOpen={setDeleteDialogOpen}
					jobId={jobId}
					setJobId={setJobId}
					jobRole={jobRole}
					setJobRole={setJobRole}
					setJobPostings={setJobPostings}
				/>
				<Header />
				<Toaster />
				<Typography
					variant="h6"
					fontWeight={'bold'}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '30px',
						marginBottom: '30px',
					}}
				>
					All Job Postings
				</Typography>

				<Box sx={{ width: '90%', margin: 'auto', marginTop: '20px' }} bgcolor={'#ECF7FA'}>
					<Paper sx={{ width: '100%', mb: 2, p: 2 }} bgcolor={'#ECF7FA'}>
						<StyledDataGrid
							columns={columns}
							rows={filteredRows} // reversing the order in which the jobs are displayed
							slots={{ toolbar: CustomToolbar }}
							disableRowSelectionOnClick
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							sx={{
								height: '80vh',
							}}
							pageSizeOptions={[5, 10, 15, 20]}
							slotProps={{
								toolbar: {
									onFilterChange: handleFilterChange,
								},
							}}
						/>
					</Paper>
				</Box>
			</div>
		</>
	);
};

export default AllJobPostingTable;
