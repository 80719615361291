import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Header } from '../shared/Header';
import { Stack } from '@mui/system';
import { Button, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Interview from '../Interview';
import { useLocation, useNavigate } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import MockInterview from '../Student/mock-interviews/MockInterview';
import { Margin } from '@mui/icons-material';

const AdminMockInterviewDashboard = () => {
	const [interviews, setInterviews] = useState([]);
	const [profileImage, setProfileImage] = useState('');
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const { state } = useLocation();
	const navigate = useNavigate();
	const fullName = `${firstName} ${lastName}`;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const getAllInterviews = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/student/mock-interviews`,
				{ search: searchTerm },
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			setInterviews(data.data);
			if (data.success) {
				console.log(data);
				// toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	useEffect(() => {
		getAllInterviews();
	}, [searchTerm, state?.refetch]);

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredInterviews = interviews.filter((interview) => {
		const searchMatch = interview.role.toLowerCase().includes(searchTerm.toLowerCase());

		return searchMatch;
	});

	return (
		<>
			{/* <Toaster /> */}
			<Header />
			<Stack
				p={{ sm: 5, xs: 3, lg: 4 }}
				spacing={{ sm: 5, xs: 3, lg: 5 }}
				style={{
					backgroundColor: '#ecf7fa',
					width: 'auto',
					minHeight: isMobile ? '100vh' : '100%',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: isMobile ? 'space-around' : 'space-around',
						alignItems: 'center',
						width: 'auto',
					}}
				>
					<Typography fontWeight={'bold'} fontSize={isMobile ? '1.1rem' : '1.3rem'}>
						All Mock-Interviews
					</Typography>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '15px',
							width: 'auto',
							marginTop: isMobile ? '10px' : '',
						}}
					>
						<TextField
							variant="outlined"
							label="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
							fullWidth={isMobile} // Full width on mobile
							InputProps={{
								endAdornment: (
									<SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
								),
							}}
						/>
						<button
							onClick={() =>
								navigate('/mock-interviews/all-reports', {
									// state: {
									// 	fullName: `${firstName} ${lastName}`,
									// },
								})
							}
							style={{
								cursor: 'pointer',
								padding: isMobile ? '8px 12px' : '10px 16px',
								height: isMobile ? '55px' : '',
								backgroundColor: '#224C84',
								borderRadius: '5px',
								color: '#fff',
								border: 'none',
								fontSize: isMobile ? '1rem' : '1.3rem',
								width: 'auto',
								transition: 'transform 0.3s ease',
								whiteSpace: 'nowrap',
							}}
							onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.04)')}
							onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
						>
							{isMobile ? (
								<Typography fontWeight={'semibold'} fontSize={'1.2rem'}>
									Reports
								</Typography>
							) : (
								<Typography
									fontWeight={'semibold'}
									fontSize={isMobile ? '1rem' : '1.3rem'}
								>
									View Interview Reports
								</Typography>
							)}
						</button>
						<button
							onClick={() => navigate('/create-mock-interviews')}
							style={{
								cursor: 'pointer',
								padding: isMobile ? '8px 12px' : '10px 16px',
								height: isMobile ? '55px' : '',
								backgroundColor: '#224C84',
								borderRadius: '5px',
								color: '#fff',
								border: 'none',
								fontSize: isMobile ? '1rem' : '1.3rem',
								width: 'auto',
								transition: 'transform 0.3s ease',
								whiteSpace: 'nowrap',
							}}
							onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.04)')}
							onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
						>
							<Typography
								fontWeight={'semibold'}
								fontSize={isMobile ? '1rem' : '1.3rem'}
							>
								Create Interview
							</Typography>
						</button>
					</div>
				</div>
				<Stack
					direction={isMobile ? 'column' : 'row'}
					useFlexGap
					flexWrap={'wrap'}
					spacing={7}
					alignItems={'center'}
					justifyContent={'center'}
				>
					{loading && (
						<Stack spacing={5} justifyContent={'start'} direction={'row'}>
							{Array(3)
								.fill(null)
								.map((_, index) => (
									<Stack
										key={index}
										p={{ sm: 3, xs: 2 }}
										bgcolor={'#fff'}
										spacing={2}
										width={{ sm: '400px', xs: '100%' }}
										height={'auto'}
										borderRadius={'20px'}
										boxShadow={'8px 6px 12px 0 rgba(13,31,61,.04)'}
									>
										<Skeleton variant="text" width={'250px'} />
										<Skeleton variant="text" width={'200px'} />
										<Skeleton variant="text" />
										<Skeleton variant="button" width={'100px'} />
										<Skeleton variant="button" width={'100px'} />
										<Skeleton variant="button" width={'100px'} />
									</Stack>
								))}
						</Stack>
					)}

					{!loading && filteredInterviews.length === 0 && (
						<Typography>No interviews available for you.</Typography>
					)}

					{!loading &&
						filteredInterviews.map((interview) => (
							<MockInterview
								interviewDescription={interview.description}
								key={interview._id}
								interview_id={interview._id}
								pilot_id={interview.pilot_id}
								role={interview.role}
								poster={interview.poster}
								industry={interview.industry}
								interview_level={interview.interview_level}
								questions={interview.questions}
								fullName={fullName}
							/>
						))}
				</Stack>
			</Stack>
		</>
	);
};

export default AdminMockInterviewDashboard;
