import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Typography, Fab, Chip, Box } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import { useNavigate } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const MockInterview = (props) => {
	const [loading, setLoading] = useState(false);
	const [fullName, setFullName] = useState(props.fullName);
	const [roles, setRoles] = useState([]);
	const navigate = useNavigate();
	console.log(props);

	useEffect(() => {
		if (sessionStorage.getItem('admin') === null) return;
		const roles = JSON.parse(sessionStorage.getItem('admin')).roles || [];
		console.log(roles, 'roles');
		setRoles(roles);
	}, []);

	const createInterview = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(
				`${API_URL}/labs/mock-interview/create/${props.interview_id}`,
				{},
				{
					headers: {
						authorization: `Bearer ${sessionStorage.getItem('student-auth-token')}`,
					},
				}
			);
			console.log(data);
			if (data.success) {
				toast.success('Interview Created Successfully');
				navigate(`/check/${fullName}/${data.data._id}`, {
					state: {
						fullName: fullName,
						interviewKey: data.data._id,
						isStudentInterview: true,
					},
				});
			} else {
				toast.error(data.message);
			}
		} catch (e) {
			console.log(e.message);
			toast.error('Something went wrong, Try Again!!');
		}
		setLoading(false);
	};

	return (
		<Card
			sx={{
				width: { xs: '88vw', sm: '80vw', md: '60vw', lg: '100vw' }, // Responsive widths
				maxWidth: 345,
				borderRadius: 4,
				margin: 'auto',
				position: 'relative',
			}}
		>
			{roles.includes('aihyr-admin') && (
				<Box
					sx={{
						position: 'absolute',
						top: 14,
						right: 14,
						width: 40,
						height: 40,
						borderRadius: '50%',
						backgroundColor: 'white',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						cursor: 'pointer',
						zIndex: 2,
						transition: 'background-color 0.3s, color 0.3s',
						'&:hover': {
							backgroundColor: 'white',
							'& .MuiSvgIcon-root': {
								color: '#224C84',
							},
						},
					}}
				>
					<EditRoundedIcon
						sx={{
							color: 'black',
							transition: 'color 0.3s',
						}}
						onClick={() => {
							navigate(
								`/edit-mock-interview/${props.interview_id}/${props.pilot_id}`
							);
						}}
					/>
				</Box>
			)}
			<CardMedia
				component="img"
				height="140"
				// width="345"
				// image="/images/mock-jobs/frontend-dev.webp"
				image={props?.poster}
				alt="image"
				sx={{
					height: '140',
					width: '330',
					objectFit: 'cover', // Ensures the image covers the area without distortion
				}}
			/>
			<CardContent>
				<Typography gutterBottom variant="h5" component="div">
					{props.role}
				</Typography>
				<Typography variant="body2" sx={{ color: 'text.secondary', height: '30px' }}>
					{props?.interviewDescription}
				</Typography>
			</CardContent>
			<CardActions sx={{ display: 'flex', justifyContent: 'space-around', margin: '4px' }}>
				<Chip label={props.interview_level} />
				<Chip label={props.questions?.length - 1 + ' Questions'} />
				<Fab
					variant="extended"
					onClick={createInterview}
					sx={{
						fontSize: { xs: '0.6rem', sm: '1rem' }, // Adjust font size for different screen sizes
					}}
				>
					Start
					<ArrowForwardIosRoundedIcon
						sx={{ mr: { lg: 1, xs: 0 }, fontSize: { xs: '1rem' } }}
					/>
				</Fab>
			</CardActions>
		</Card>
	);
};

export default MockInterview;
